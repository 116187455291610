import "./App.css";
import Download from "./components/Download/Download";
import Features from "./components/Features/Features";
import Footer from "./components/Footer/Footer";
import Jumbo from "./components/Jumbo/Jumbo";
import Roadmap from "./components/Roadmap/Roadmap";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import React, { useEffect } from "react";
import Register from "./components/Register/Register";
import Token from "./components/Token/Token";
import Cooperation from "./components/Cooperation/Cooperation";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import CookieBanner from "react-cookie-banner/lib";
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS;
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_SECRET;

const styles = {
  banner: {
    height: 57,
    background: "rgba(52, 64, 81, 0.60) url(/cookie.png) 18px 20% no-repeat",
    backgroundSize: "40px 40px",
    backgroundColor: "",
    fontSize: "15px",
    fontWeight: 600,
  },
  button: {
    border: "1px solid white",
    borderRadius: 4,
    width: 66,
    height: 32,
    lineHeight: "32px",
    background: "transparent",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -18,
  },
  message: {
    display: "block",
    padding: "9px 67px",
    lineHeight: 1.3,
    textAlign: "left",
    marginRight: 244,
    color: "white",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
  },
};

function App() {
  // For Intercom
  function loadIntercom() {
    if (window.Intercom) {
      window.Intercom("boot", {
        app_id: INTERCOM_APP_ID,
        //...other info here
      });
    }
    return () => window.Intercom("shutdown");
  }

  useEffect(() => {
    loadIntercom();
  });

  return (
    <div>
      <div className="App">
        <div className="flex w-full justify-end p-5">
          <button
            type="button"
            className="mr-3 inline-flex items-center px-3 py-2 border border-gray-300 text-white shadow-sm text-sm leading-4 font-medium rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
          >
            Login
          </button>
          <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-gray-300 text-white shadow-sm text-sm leading-4 font-medium rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
          >
            Register
          </button>
        </div>
        <div className="fixed bottom-0 left-0 right-0 z-50">
          <CookieBanner
            styles={styles}
            message=""
            link={
              <a href="https://google.com/">
                This website uses cookies to enhance the users experience.
              </a>
            }
            buttonMessage="Close"
            dismissOnScroll={false}
            dismissOnClick={true}
            onAccept={() => ReactGA.initialize(TRACKING_ID)}
          />
        </div>
        <div className="background">
          <img src="./tulpen_logo.svg" class="icon" alt="logo" />
        </div>
        <div className="overlay">
          <div className="flex flex-col justify-center">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
              <div className="center text-center">
                <img
                  src="./tulpen_logo.svg"
                  alt="small-logo"
                  className="w-32"
                />
                <h2 className="text-base font-semibold text-white-600 tracking-wide uppercase">
                  Tulipex
                </h2>
                <p className="mt-5 text-4xl font-extrabold text-white-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                  Initial Token Offering soon in Q2 2022
                </p>
                <p className="max-w-xl mt-5 mx-auto text-xl text-white-500">
                  Making Crypto and DeFi simple &amp; effective. A
                  Cryptocurrency exchange that thinks different - An Ecosystem
                  that benefits its users - A Vision that has longterm prospect
                </p>
              </div>
            </div>
            {false && (
              <div className="headline">
                <div className="headline-content">
                  <img
                    src="./tulpen_logo.svg"
                    alt="small-logo"
                    className="headline-content"
                  />

                  <h1>Making Crypto and DeFi simple &amp; effective</h1>
                  <h2>Initial Token Offering soon in Q1 2022</h2>
                  {false && (
                    <div>
                      <h2>From Hodlers for Hodlers</h2>
                      <h3>
                        A Cryptocurrency exchange that thinks different - An
                        Ecosystem that benefits its users - A Vision that has
                        longterm prospect
                      </h3>
                      <h5>
                        {" "}
                        Talk to us in th Chat - whether you are an Investor,
                        User or just curious!
                      </h5>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            <Register />

            <Jumbo
              component={
                <div className="download-container">
                  <Download />
                </div>
              }
              title="What is TLP"
              text="TLP is Tulipex.io’s native utility token, central to the ecosystem.

            We distribute 50% of all Fee-income to the Hodlers of TLP monthly, enabling groundbreaking rewards for users. "
            />

            <Jumbo
              title="What is Tulipex.io"
              text="Tulipex is a 3rd generation cryptocurrency exchange, emphasizing
      DeFi integration and continous innovation as its core features,
      maximizing value for the user. Read more in our Violetpaper."
            />

            <Token />
            <div className="flex justify-center p-5">
              <Features />
            </div>
            <Roadmap />
            <Cooperation />
            <Footer />
            <IntercomProvider appId={INTERCOM_APP_ID}></IntercomProvider>
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
}

export default App;
