import React from "react";
import "./Roadmap.css";
const items = [
  {
    timestamp: "Q1 2022",
    text: "Token launch & source release on github | Presale and sale start | Beta Tulipex.io and FAAST",
  },
  {
    timestamp: "Q2 2022",
    text: "Airdrop campaigns | Tulipex and FAAST public beta | Reward service implementation | 1% of TLP burned",
  },
  {
    timestamp: "Q3 2022",
    text: "Recruiting-rewards & airdrops | First rewards distributed | DeFi Integration | 1% of TLP burned",
  },
  {
    timestamp: "Q4 2022",
    text: "Staking products | Polkadot Subchain development| 1% of TLP burned",
  },
  {
    timestamp: "Q1 2023",
    text: "Savings products | Initial coin listing partnerships | TLC subchain development | 1% of TLP burned",
  },
  {
    timestamp: "Q2 2023",
    text: "Polkadot subchain reward-contract release | Migration to polkadot on TLC subchain | 1% of TLP burned",
  },
  {
    timestamp: "Q3 2023",
    text: "TLP DeFi products development on TLC native chain | 1% of TLP burned",
  },
];
const Roadmap = () => {
  const renderEntries = () => {
    return items.map((item, key) => {
      return (
        <div key={key} className="roadmap-item">
          <div className="roadmap-bubble">{item.timestamp}</div>
          <div className="roadmap-text"> {item.text}</div>
        </div>
      );
    });
  };
  return (
    <div className="roadmap">
      <div className="roadmap-container">
        <div className="roadmap-title">Roadmap</div>
        <div className="mt-3 flex flex-wrap justify-center">
          {renderEntries()}
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
