import React from "react";
import "./Jumbo.css";
import CenteredComponent from "../CenterComponent/CenteredComponent";

const Jumbo = ({ title, text, component }) => {
  return (
    <div className="jumbo">
      <CenteredComponent>
        <div className="jumbo-title">{title}</div>
        <div className="jumbo-subtitle">{text}</div>
        {component}
      </CenteredComponent>
    </div>
  );
};

export default Jumbo;
