import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import "./Register.css";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

const finishDate = moment("27/07/2022", "DD/MM/YYYY");

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

const Register = () => {
  const recaptchaRef = useRef(null);
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var end = finishDate.toDate();

    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    var timer;

    function showRemaining() {
      var now = new Date();
      var distance = end - now;
      if (distance < 0) {
        clearInterval(timer);

        return;
      }
      var days = pad(Math.floor(distance / _day), 2);
      var hours = pad(Math.floor((distance % _day) / _hour), 2);
      var minutes = pad(Math.floor((distance % _hour) / _minute), 2);
      var seconds = pad(Math.floor((distance % _minute) / _second), 2);
      setDate(days + ":" + hours + ":" + minutes + ":" + seconds);
    }

    timer = setInterval(showRemaining, 1000);
  }, []);

  const handleSubmit = async () => {
    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    try {
      setLoading(true);
      const response = await axios.post(
        "https://tulipex-sub.herokuapp.com/subscribe",
        {
          user: {
            role: "user",
            email: email,
            custom_attributes: {
              wanna_spend: price,
            },
          },
          captchaToken,
        }
      );
      if (response.status === 204) {
        toast.success("You have successfully subscribed 🎉");
        setEmail("");
        setPrice("");
        setLoading(false);
      }
    } catch (ex) {
      toast.error("Something went wrong. Please contact us.");
      setLoading(false);
    }
  };

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    toast.error("You have entered an invalid email address!");
    return false;
  };

  return (
    <div className="register">
      <div className="bg-white shadow sm:rounded-lg flex flex-col justify-center items-center p-5 content-center">
        <div className="flex w-full justify-end">
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium text-white pill">
            OPEN
          </span>
        </div>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-8 px-4 sm:py-3 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-base font-semibold text-red-600 tracking-wide uppercase">
                ITO
              </h2>
              <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight lg:text-6xl">
                Presale1 Registration
              </p>
            </div>
          </div>
        </div>
        <div>Estimated Presale Start in </div>
        <div className="text-3xl mb-5 sm:text-4xl sm:tracking-tight lg:text-5xl lg:mb-3">
         {date}
        </div>
        <div className="isolate -space-y-px rounded-md shadow-sm lg:w-3/4 w-full">
          <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-700"
            >
              E-Mail
            </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              className="w-full focus:outline-none block border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
              placeholder="youremail@mail.com"
            />
          </div>
          <div className="relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="job-title"
              className="block w-full text-xs font-medium text-gray-700"
            >
              How much do you like to invest (optional) (€, BTC, ETH, USDC)
            </label>
            <input
              onChange={(event) => {
                setPrice(event.target.value);
              }}
              value={price}
              name="amount"
              className="focus:outline-none block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
              placeholder="e.g. 3000 €"
            />
          </div>

          <div className="relative border border-gray-300  px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="job-title"
              className="block w-full text-xs font-medium text-gray-700"
            >
             Total
            </label>
            <label
              name="amount"
              className="focus:outline-none block w-full border-0 p-0 text-gray-700  focus:ring-0 sm:text-sm"
            >5.000.000 TLP</label>
          </div>
          <div className="relative border border-gray-300  px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="job-title"
              className="block w-full text-xs font-medium text-gray-700"
            >
            Available
            </label>
            <label
              name="amount"
              className="focus:outline-none block w-full border-0 p-0 text-green-700 focus:ring-0 sm:text-sm"
            >3.530.000 TLP</label>
          </div>
          <div className="relative border border-gray-300 rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="job-title"
              className="block w-full text-xs font-medium text-gray-700"
            >
            Reserved so far
            </label>
            <label
              name="amount"
              className="focus:outline-none block w-full border-0 p-0 text-red-700 focus:ring-0 sm:text-sm"
          >1.470.000 TLP</label>
          </div>
        </div>

        All unsold TLP will be burned!
        <div className="flex justify-end lg:w-3/4 w-full">
          {!loading && (
            <button
              onClick={() => {
                if (validateEmail(email)) {
                  handleSubmit();
                }
              }}
              type="button"
              className="mt-3 lg:w-44 w-full flex justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Register
            </button>
          )}
          {loading && (
            <div
              type="button"
              className="mt-3 lg:w-44 w-full flex justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Loading ..
            </div>
          )}
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_SITE_KEY}
            size="invisible"
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
