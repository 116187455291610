import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          <a href="https://t.me/TulipexIo">
            <img src="./telegram.svg" className=" w-11" />
          </a>
          <a href="https://twitter.com/Tulipexio" className="p-3">
            <img className="text-white w-6" src="./twitter.svg" />
          </a>
          <a href="https://www.facebook.com/tulipex.io" className="p-3">
            <img className="text-white w-6" src="./facebook.svg" />
          </a>
          <a href="https://www.reddit.com/r/Tulipexio" className="p-3">
            <img className="text-white w-6" src="./reddit.svg" />
          </a>
          <a href="https://www.instagram.com/tulipex.io" className="p-3">
            <img className="text-white w-6" src="./instagram.svg" />
          </a>
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-white-400">
            TLP Contract on Etherscan:<br></br>
            <a
              href="https://etherscan.io/token/0x937f3d2536bb4796b7040252ae4332370551f633"
              className="break-words"
            >
              0x937f3d2536bb4796b7040252ae4332370551f633
            </a>
          </p>
        </div>

        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-white-400">
            TLP Contract on Fuse:<br></br>
            <a
              href="https://explorer.fuse.io/token/0x802D892a9D8BD996bF757142456E0556056EcA6C"
              className="break-words"
            >
              0x802D892a9D8BD996bF757142456E0556056EcA6C
            </a>
          </p>
        </div>

      </div>
      <div className="mb-8 md:mt-0">
        <p className="text-center text-base text-white-400">
          &copy; Blackriver FinTech OÜ in Foundation, 2022 <br></br>
          Tallinn, Estonia info[@]tulipex.io limited company in foundation
          process
        </p>
      </div>
    </footer>
  );
  return (
    <div className="footer p-3">
      <div className="text-center">
        © Blackriver FinTech OÜ in Foundation, 2022<br></br>
        <p>
          Tallinn, Estonia info[@]tulipex.io limited company in foundation
          process
        </p>
        <div className="center linksdiv">
          TLP Contract on Etherscan:{" "}
          <a href="https://etherscan.io/token/0x937f3d2536bb4796b7040252ae4332370551f633">
            0x937f3d2536bb4796b7040252ae4332370551f633
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
