import "./Token.css";

const Token = () => {
  return (
    <div className="register">
      <div className="bg-white shadow sm:rounded-lg flex flex-col justify-center items-center p-5 content-center">
        <div className="flex w-full justify-end">
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium text-white pill">
            INFO
          </span>
        </div>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-8 px-4 sm:py-3 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-base font-semibold text-red-600 tracking-wide uppercase">
                TLP ERC20
              </h2>
              <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                Token Details
              </p>
            </div>
          </div>
        </div>

        <div className="isolate -space-y-px rounded-md shadow-sm w-full lg:w-3/4">
          
          <div className="relative border border-gray-300  px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="name"
              className="block w-full text-xs font-medium text-gray-700"
            >
              Total Maximum Supply
            </label>
            <label>97 020 000 TLP</label>
          </div>

          <div className="relative border border-gray-300  px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="job-title"
              className="block w-full text-xs font-medium text-gray-700"
            >
              Circulating Supply
            </label>
            <label>0 TLP</label>
          </div>

          <div className="relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="job-title"
              className="block w-full text-xs font-medium text-gray-700"
            >
              Decimals
            </label>
            <label>7</label>
          </div>

          <div className="relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="job-title"
              className="block w-full text-xs font-medium text-gray-700"
            >
              Networks
            </label>
            <label>Ethereum, Fuse</label>
          </div>

          <div className="relative border border-gray-300  px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="job-title"
              className="block w-full text-xs font-medium text-gray-700"
            >
              Uniswap V2
            </label>
            <label>WETH:TLP</label>
          </div>

          <div className="relative border border-gray-300 rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="job-title"
              className="block w-full text-xs font-medium text-gray-700"
            >
              Fuse.fi Swap
            </label>
            <label>WETH:TLP FUSE:TLP USDC:TLP</label>
          </div>

          <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-700"
            >
              Contract Address Ethereum Mainnet
            </label>
            <label htmlFor="name" className="break-words">
              0x937f3d2536bb4796b7040252ae4332370551f633
            </label>
          </div>
          <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-red-600 focus-within:border-red-600">

          <label
            htmlFor="name"
            className="block text-xs font-medium text-gray-700"
            >
              Contract Address Fuse Network
            </label>
            <label htmlFor="name" className="break-words">0x802D892a9D8BD996bF757142456E0556056EcA6C</label>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Token;
