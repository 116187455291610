import React from "react";
import "./Download.css";

const url =
  "https://tulipex.s3.eu-central-1.amazonaws.com/Tulipex+Token+TLP+Whitepaper+v0.9.pdf";

const Download = () => {
  return (
    <a className="download" href={url} rel="external" target="_blank">
      <div className="download-text">
        <div className="download-title">Download our whitepaper</div>
        <div className="download-subtitle">Version 0.9 as of January 2022</div>
      </div>
      <div className="download-left">
        <img className="icon-down" src="download.svg" alt="save" />
      </div>
    </a>
  );
};

export default Download;
