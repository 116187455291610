import React from "react";
import "./Cooperation.css";

const items = [
  {
    logo: "fusion.svg",
    text: "Tulipex.io integrates fully into the Fuse.fi Ecosystem",
  },
  {
    logo: "faircoinlogo.png",
    text: "We are happy to announce the support and listing of Faircoin in an effort to support a longstanding social crypto community",
  },
  {
    logo: "GoodDollarLogo.png",
    text: "Crypo enables disruptive financial tools, Gooddollars Universal Basic Income is one we want to support",
  },
];
const Cooperation = () => {
  const renderEntries = () => {
    return items.map((item, key) => {
      return (
        <div key={key} className="cooperation-item">
          <div className="cooperation-logo">
            <img src={item.logo} />
          </div>
          <div className="cooperation-text"> {item.text}</div>
        </div>
      );
    });
  };
  return (
  
    <div className="cooperation">
      <div className="cooperation-container">
        <div className="cooperation-title">Featuring / Cooperation</div>
        <div
          style={{
            marginTop: 50,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {renderEntries()}
        </div>
      </div>
    </div>

  );
};

export default Cooperation;
