import React from "react";
import "./Features.css";
import Card from "../Card/Card";
import CenteredComponent from "../CenterComponent/CenteredComponent";
/**
 *  <Card title="100.000.000 TLP total supply" icon="supply.svg" />
          <Card title="1% of TLP burned quarterly" icon="burn.svg" />
          <Card title="50% of Fee redistributed" icon="coin.svg" />
          <Card title="From Hodlers for Hodlers" icon="hodler.svg" />
          <Card title="100k Anti-Whale Barrier" icon="whale.svg" />
          <Card title="Paramount Security" icon="security.svg" />
          <Card title="Fees as low as 0.075%" icon="fee.svg" />
          <Card title="Full transparency" icon="transparancy.svg" />
          <Card title="DeFi Savings" icon="money-bag.svg" />
 */
const features = [
  {
    title: "98.000.000 TLP total supply",
    icon: "supply.svg",
  },
  { title: "1% of TLP burned quarterly", icon: "burn.svg" },
  { title: "50% of Fee redistributed", icon: "coin.svg" },
  { title: "From Hodlers for Hodlers", icon: "hodler.svg" },
  { title: "100k Anti-Whale Barrier", icon: "whale.svg" },
  { title: "Paramount Security", icon: "security.svg" },
  { title: "Fees as low as 0.075%", icon: "fee.svg" },
  { title: "Full transparency", icon: "transparancy.svg" },
  { title: "DeFi Savings", icon: "money-bag.svg" },
];

const Features = () => {
  return (
    <div className="w-full lg:w-3/4">
      <h2 className="mb-5">Keynotes for TLP and Tulipex.io</h2>
      <div className="flex justify-center ">
        <ul
          role="list"
          className="grid grid-cols-2 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
        >
          {features.map((feature, key) => (
            <li
              key={key}
              className="aspect-square col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 "
            >
              <div className="flex-1 flex flex-col justify-center items-center">
                <div className="lg:w-32 lg:h-32 flex-shrink-0 mx-auto rounded-full flex justify-center items-center">
                  <img className="lg:p-5 w-2/4 lg:w-3/4" src={feature.icon} />
                </div>
                <h3 className=" font-bold text-gray-900 text-sm  p-3 pt-0 font-medium">
                  {feature.title}
                </h3>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Features;
