import React from "react";
import "./CenterComponent.css";

const CenteredComponent = ({ children }) => {
  return (
    <div className="center-container">
      <div className="center">{children}</div>
    </div>
  );
};

export default CenteredComponent;
